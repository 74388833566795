import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/home/HomeView.vue"
import { useAppStore } from "@/store/index.js"

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/admin",
        name: "AdminView",
        component: () => import("../views/admin/AdminView.vue"),
        redirect: "/admin/payment/recharge",
        children: [
            {
                path: "/admin/payment/list",
                name: "PaymentList",
                component: () => import("@/views/admin/payment/PaymentList.vue"),
            },
            {
                path: "/admin/payment/edit",
                name: "CompanyEdit",
                component: () => import("@/views/admin/payment/PaymentEdit.vue"),
            },
            {
                path: "/admin/user/list",
                name: "UserList",
                component: () => import("@/views/admin/user/UserList.vue"),
            },
            {
                path: "/admin/payment/recharge",
                name: "RechargeBalance",
                component: () => import("@/views/admin/payment/RechargeBalance.vue"),
            },
            {
                path: "/admin/transaction/list",
                name: "TransactionList",
                component: () => import("@/views/admin/transaction/TransactionList.vue"),
            },
            {
                path: "/admin/transaction/detail/:id",
                name: "TransactionDetail",
                component: () => import("@/views/admin/transaction/TransactionDetail.vue"),
            },
            {
                path: "/admin/setting/update-password",
                name: "UpdatePassword",
                component: () => import("../views/admin/setting/UpdatePassword.vue"),
            },
            {
                path: "/admin/user/admin-list",
                name: "AdminList",
                component: () => import("../views/admin/user/AdminList.vue"),
            },
            {
                path: "/admin/add",
                name: "AdminAdd",
                component: () => import("../views/admin/user/AdminAdd.vue"),
            },
            {
                path: "/admin/pay-record/list",
                name: "PayRecordList",
                component: () => import("../views/admin/record/PayRecordList.vue"),
            },
            {
                path: "/admin/payment/document/download",
                name: "DownloadDocument",
                component: () => import("../views/admin/payment/DownloadDocument.vue"),
            },
            {
                path: "/admin/payment/document/report",
                name: "ReportView",
                component: () => import("../views/admin/payment/ReportView.vue"),
            },
            {
                path: "/admin/action/list",
                name: "ActionList",
                component: () => import("../views/admin/action/ActionList.vue"),
            },
            {
                path: "/admin/action/edit/:id",
                name: "ActionAddEdit",
                component: () => import("../views/admin/action/ActionAddEdit.vue"),
            },
            {
                path: "/admin/transaction/recompile",
                name: "TransactionRecompile",
                component: () => import("../views/admin/transaction/TransactionRecompile.vue"),
            },
        ],
    },
    {
        path: "/login",
        name: "LoginView",
        component: () => import("../views/auth/LoginView.vue"),
    },
    {
        path: "/admin/test",
        name: "TestView",
        component: () => import("../views/TestView.vue"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const storeApp = useAppStore()
    if (storeApp.hasLogin === false && to.path.indexOf("/login") == -1 && to.path.indexOf("/admin") != -1) {
        next({
            name: "LoginView",
        })
        return
    } else {
        next()
        return
    }
})

export default router
